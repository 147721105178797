.sher-sidebar {
  width: 100%;
  background-color: #f8f8f8;
  padding: 20px;
  border-top: 2px solid #ddd;
  overflow-y: auto;
  margin-top: 20px;
  text-align: center;
}

.sher-sidebar h2 {
  font-size: 1.4rem;
  margin-bottom: 10px;
}

.sher-sidebar ul {
  list-style: none;
  padding: 0;
}

.sher-sidebar li {
  padding: 12px;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.sher-sidebar li:hover {
  background: #e0e0e0;
}

.sher-text {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  white-space: pre-line;  /* ✅ Preserving line breaks */
}
