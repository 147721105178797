.collab-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.collab-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.collab-description {
  color: #4a4a4a;
  text-align: left;
  margin-bottom: 20px;
  padding-inline: 20px;
}

/* Align Logo and Name in One Line */
.collab-header {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between logo and name */
}

.collab-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.collab-name {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.collab-card {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  margin-top: 20px;
}



.collab-meta {
  color: #666;
  margin: 5px 0;
}

.ppl {
  font-size: 1rem;
  font-weight: bold;
  padding-block: 10px;
}

.collab-link {
  color: #0077cc;
  text-decoration: underline;
  font-weight: bold;
}

.collab-link:hover {
  color: #005fa3;
}

.coll-link {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-block: 5px;
}
