.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: var(--bg-color-light);
  border-bottom: 2px solid rgba(211, 211, 211, 0.425);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
}

/* Left section (logo) */
.header-left {
  display: flex;
  align-items: center;
}

/* Logo Styling */
.logo img {
  width: 50px;
  height: auto;
  object-fit: contain;
}

/* Centered Titles */
.header-center {
  text-align: center;
  flex-grow: 1;
}

/* Fixed Title (RekhtaLabs) */
.fixed-title {
  font-size: 1.6rem;
  font-weight: bold;
  color: var(--text-color-dark);
  text-decoration: none;
  display: block;
}

.fixed-title:hover {
  color: #007bff;
}

/* Remove click behavior on home page */
.not-clickable {
  pointer-events: none;
  cursor: default;
}

/* Page Title (Dynamic Title from Props) */
.header-page-title {
  font-size: 1.1rem;
  color: #313131;
  margin-top: 2px;
}

/* Right Side (Login/User Info) */
.header-right {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-right: 10px;
}

.header-right a, .header-right span {
  color: var(--text-color-dark);
  text-decoration: none;
}
