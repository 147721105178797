.project-detail-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  min-height: 85vh;
}

.project-image {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: 10px;
  margin: 20px 0;
}

.project-description {
  font-size: 16px;
  line-height: 1.6;
}

/* Back to Projects Link */
.back-to-projects {
  margin-top: 30px;
  text-align: center;
}

.back-link {
  display: inline-block;
  padding: 10px 15px;
  background-color: #333;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.back-link:hover {
  background-color: #555;
}

.in-progress { background-color: rgba(255, 246, 161, 0.809); color: #333; }
.completed { background-color: rgba(47, 159, 51, 0.718); color: #333; }
.maintenance { background-color: rgba(47, 159, 51, 0.718); color: #333; }
.concept-phase { background-color: rgba(221, 216, 114, 0.65); color: #333; }
.pending { background-color: rgba(255, 129, 117, 0.797); color: #333; }