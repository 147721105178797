.projects-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 5px;
}

.page-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

/* Project Card Styling */
.project-card {
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.project-card:nth-child(even) {
  background-color: #f4f4f4;
}

.project-card:nth-child(odd) {
  background-color: #e0e0e0;
}

.project-card:hover {
  transform: translateY(-3px);
}

.project-card img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;
}

.project-card h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 10px;
}

.project-status {
  display: inline-block;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 5px;
}

/* Status Colors */

.project-status { background-color: rgba(255, 129, 117, 0.581); color: #333; }
.status-in-progress { background-color: rgba(255, 246, 161, 0.809); color: #333; }
.status-completed { background-color: rgba(47, 159, 51, 0.718); color: #333; }
.status-maintenance { background-color: rgba(47, 159, 51, 0.718); color: #333; }
.status-concept-phase { background-color: rgba(221, 216, 114, 0.65); color: #333; }
.status-pending { background-color: rgba(255, 129, 117, 0.797); color: #333; }

/* View Project Link */
.view-project {
  display: inline-block;
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #333;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  transition: background 0.2s;
}

.view-project:hover {
  background-color: #555;
}
