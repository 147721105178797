.demos-page {
    text-align: center;
    /* padding: 40px 20px; */
    /* padding-top: 0; */
    /* background-color:aliceblue; */

}

.demos-content {
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 40px;
    flex-grow: 1;
    min-height: 100vh;
    /* background-color: antiquewhite; */
}

/* Alternating background colors */
.demo-card:nth-child(odd) {
    background: #f9f9f9; /* Light gray */
}

.demo-card:nth-child(even) {
    background: #ffffff; /* Pure white */
}

/* Card Styling */
.demo-card {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
}

.demo-card:hover {
    transform: translateY(-5px);
}

/* Alternating Layout */
.demo-card-content {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
}

.demo-card.left .demo-image-container {
    order: -1;
}

/* Image Styling */
.demo-image-container {
    flex: 1;
    max-width: 40%;
}

.demo-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
}

/* Text Styling */
.demo-text {
    flex: 1;
    text-align: left;
}

.demo-title {
    font-size: 1.6rem;
    font-weight: bold;
    color: #222;
    margin-bottom: 10px;
}

.demo-short-desc {
    font-size: 1rem;
    font-weight: bold;
    color: #444;
    margin-bottom: 8px;
}

.demo-long-desc {
    font-size: 0.95rem;
    color: #666;
    line-height: 1.5;
    margin-bottom: 12px;
}

/* Fix CTA Button Alignment */
.demo-cta-container {
    display: flex;
    width: 100%;
    margin-top: 10px;
}

.demo-card.left .demo-cta-container {
    justify-content: flex-start; /* Align left when text is on the left */
}

.demo-card.right .demo-cta-container {
    justify-content: flex-end; /* Align right when text is on the right */
}

/* CTA Button */
.demo-cta {
    display: inline-block;
    background-color: #444;
    color: white;
    text-decoration: none;
    padding: 10px 18px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    transition: background 0.2s ease-in-out;
    text-align: center;
    text-transform: uppercase;
}

.demo-cta:hover {
    background-color: #222;
}

/* Responsive Design */
@media (max-width: 768px) {
    .demo-card-content {
        flex-direction: column;
        text-align: center;
    }

    .demo-image-container {
        max-width: 100%;
    }

    .demo-card.left .demo-image-container {
        order: unset;
    }

    .demo-cta-container {
        justify-content: center !important;
    }
}
