.page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.overall-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 90vh;
}

/* ✅ Ensures the input box does not overlap */
.textarea-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px; /* ✅ Adds space to prevent overlap */
}

textarea {
  width: 90%;
  max-width: 1600px;
  height: 120px;
  min-height: 120px;
  text-align: center;
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* ✅ Ensures button stays below the textarea */
.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px; /* ✅ Adds space before saved couplets */
}

button {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: #3498db;
  color: white;
  border-radius: 5px;
  font-size: 16px;
}

/* ✅ Fix the saved couplets section */
.sher-sidebar {
  width: 100%;
  padding: 20px;
  border-top: 2px solid #ddd;
  overflow-y: auto;
  text-align: center;
  margin-top: 20px; /* ✅ Pushes saved couplets down */
}

.sher-sidebar h2 {
  font-size: 1.4rem;
  margin-bottom: 10px;
}

.sher-sidebar ul {
  list-style: none;
  padding: 0;
}

.sher-sidebar li {
  padding: 12px;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.sher-sidebar li:hover {
  background: #e0e0e0;
}

/* ✅ Ensure sher text preserves formatting */
.sher-text {
  white-space: pre-line;
  font-size: 16px;
  color: #333;
}
