.home-page {
  text-align: center;
  flex-grow: 1;
}

main {
  flex-grow: 1;
  min-height: 90vh;
}

/* Hero Section */
.hero {
  /* background: linear-gradient(135deg, #282c34, #3f4656); */
  background: linear-gradient(0deg, #222, #333);
  color: white;
  padding: 60px 20px;
  border-radius: 0px 0px 12px 12px;
  text-align: center;
  max-width: 800px;
  margin: auto;
}

.hero h1 {
  font-size: 2rem;
  margin-bottom: 15px;
  font-weight: bold;
}

.hero p {
  font-size: 1.1rem;
  margin-bottom: 20px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
}

.hero p.hero-title{
  font-size: 1.4rem;

}

/* CTA Button */
.cta-button {
  background-color: #ff9800;
  color: white;
  text-decoration: none;
  padding: 12px 24px;
  border-radius: 5px;
  font-size: 1.1rem;
  font-weight: bold;
  transition: background 0.2s ease-in-out;
  display: inline-block;
}

.cta-button:hover {
  background-color: #e68900;
}

/* Section Styling */
.main-sections {
  margin: 5px auto;
  max-width: 800px;
  text-align: left;
  padding: 30px 20px; /* Ensure consistent spacing */
  border-radius: 10px;
}

/* Research & Archive Sections */
.research, .archive-resources, .home-wishlist {
  background: #f9f9f9;
  padding: 30px 20px;
  border-radius: 10px;
}

/* Section Titles */
.section-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

/* Research Section */
.research ul {
  list-style-type: disc; /* Changed from none to disc */
  padding-left: 20px; /* Proper alignment */
}

.research ul li {
  margin-bottom: 8px;
  font-size: 1rem;
  line-height: 1.6;
}

.research ul li strong {
  color: #222;
}

/* Archive Section */
.archive-resources a {
  color: #007bff;
  text-decoration: none;
}

.archive-resources a:hover {
  text-decoration: underline;
}

/* Projects Section on HomePage */
.projects-home {
  max-width: 800px;
  margin: auto;
  text-align: left;
  padding: 40px 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
}

/* Grid Layout for Projects */
.projects-grid-home {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
}

/* Project Card Styling */
.project-card-home {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.project-card-home:hover {
  transform: translateY(-3px);
}

.project-card-home h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.project-card-home p {
  font-size: 0.95rem;
  color: #555;
  margin-bottom: 10px;
}

/* New CTA Button for Projects */
.projects-cta-button {

  background-color: #333;
  color: white;
  text-decoration: none;
  padding: 12px 24px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  transition: background 0.2s ease-in-out;
  display: inline-block;
  margin-top: 20px;
}

.projects-cta-button:hover {
  background-color: #555;
}

/* View Project Link */
.view-project-link {
  color: #333;
  text-decoration: underline;
  font-weight: bold;
}

.view-project-link:hover {
  text-decoration: none;
}


.wishlist-cta {
  text-align: center;
  /* color: var(--primary-color, #333); */
  /* font-size: 0.8rem; */
  /* font-weight: bold; */
  /* margin-top: 20px; */
  margin: 0;
  padding: 0;
}

.wishlist-cta  a {
  color: #007bff;
  text-decoration: none;
}

.wishlist-cta  a:hover {
  text-decoration: underline;
}

.whats-new {
  display: inline-block;
  background-color: #ff7e7e3d; 
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  font-size: 1.1rem;
  font-weight: bold;
  transition: background 0.2s ease-in-out;
  transition: font-size 0.2s ease-in-out;
  margin-top: 10px;
}
.whats-new a{
  color: rgb(185, 0, 0);
}

.whats-new:hover {
  background-color: #ff7e7e5e;
  text-decoration: none;
  font-size: 1.12rem;
}
