.loginpage{
    text-align: center;
}
.inputs{
    padding-top: 20vh;
    /* font-size: 2rem; */
}
.inputs div{
    padding: 5px;
}
.subscribe{
    margin-top: 2rem;
    text-align: center;
}