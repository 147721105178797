/* General Container */
.wishlist-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: "Arial", sans-serif;
  color: var(--text-color, #333);
  line-height: 1.6;
}

/* Headings */
h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: var(--primary-color, #222);
}

/* Remove vertical line */
h2::before {
  content: none !important;
}

/* Sections */
.wishlist-section {
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 12px;
  background: var(--bg-light, #f9f9f9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.wishlist-section:hover {
  transform: translateY(-3px);
}

/* Lists */
ul {
  margin-left: 20px;
}

ul li {
  font-size: 1.1rem;
  margin-bottom: 8px;
  padding-left: 10px;
  position: relative;
  list-style-type: none;
}

ul li::before {
  content: "•";
  color: var(--primary-color, #888);
  font-size: 1.2rem;
  position: absolute;
  left: -10px;
}


/* Wishlist */

.wishlist-image-container {
  text-align: center;
  margin: 0;
  padding: 0;
  width: 100%; /* Ensure it spans full width */
  display: flex;
  justify-content: center; /* Centers the image */
  align-items: center;
}

/* Ensure the image takes full width without gaps */
.wishlist-image {
  max-width: 100%;
  height: auto;
  /* border-radius: 10px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: block; /* Removes inline spacing */
  margin: 0; /* Ensures no additional spacing */
  padding: 0; /* Ensures no extra padding */
  border: 20px white solid;
}

/* Total Cost Styling */
.wishlist-total {
  text-align: right;
}

/* Amazon Wishlist Link */
.wishlist-link {
  color: #333;
  text-decoration: underline;
  font-weight: bold;
  margin-left: 5px;
}

.wishlist-link:hover {
  color: #000;
}




/* Donation Button */
.donate-button {
  display: inline-block;
  background-color: var(--primary-color, #333);
  color: #fff;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 12px 20px;
  margin-top: 15px;
  border: none;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  transition: background 0.3s ease-in-out;
}

.donate-button:hover {
  background-color: var(--button-hover, #555);
  text-decoration: none;
}


